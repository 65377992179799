import React from 'react'
import './cta.css'

const CTA = () => {
  return (
    <div className='gpt3__cta'>
      <div className="gpt3__cta-content">
        <p>Ready to start your journy with SAP?</p>
        <h3>Download our brochure to know more about our services by clicking here... </h3>
      </div>
      <div className="gpt3__cta-btn">
      <a href="https://drive.google.com/file/d/1s7yBM4el0tSbL7Ykhqs-u-jDlv9SgQUN/view?usp=sharing" target="_blank">
        <button type="button">Download</button>
      </a>
      </div>
    </div>
  )
}

export default CTA
import React from 'react';
import gpt3Logo from '../../assets/logo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Have any query?<br/> Click bellow...</h1>
    </div>

    <a href="https://docs.google.com/forms/d/e/1FAIpQLScWIWuzkFxK1ObkUrU6dPDiTpvgbi-y8KVVgh8ogYlHcknWAA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
  <div className="gpt3__footer-btn">
    <p>Click For Form</p>
  </div>
</a>


    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
        <p>Designed and Developed by Tushar, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>9th Floor, C-64, Jomer Symphony,<br/>Vyttila,Ernakulam Kochi,<br/>Kerla 682028</p>
        <p><a href="tel:+91 8218669811">+91 8218669811</a></p>
        <p><a href="mailto:info@sapitlimited.com">info@sapitlimited.com</a></p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2024. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possiblity.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>SAP is the best option to grow your business.</h4>
      <h1 className="gradient__text">What all we offer:</h1>
      <p>Here at SAP we provide every solution to automate and run your businness smoothly...
        <li>Accounting Softwares</li>
        <li>Business Software</li>
        <li>Industry Software</li>
        <li>Software for small and midsize enterprises</li>
        <li>Platforms and frameworks</li>
        <li>Legacy Platforms</li>
        <li>ERP software</li>
        And Many More.....

      </p>
      <h4>SAP ERP allows you to build high-efficiency communication modes to streamline the entire communication process.</h4>
    </div>
  </div>
);

export default Possibility;
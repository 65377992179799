import React from 'react'
import './features.css'
import { Feature } from '../../components'

const featuresData=[
  {
    title: 'Data Security',
    text: 'SAP applications are pivotal in executing business processes and managing vast amounts of sensitive data. Protecting SAP systems from unauthorized access is critical for safeguarding company data integrity and security. Implementing preventive measures such as continuous monitoring, robust firewall configurations, and advanced security software is essential to ensure comprehensive data protection.'
  },

  {
    title: 'Data centralization',
    text: 'SAP consolidates data from various processes and business functions into a unified, centralized location.'
  },

  {
    title: 'Scalability',
    text: 'Our solutions scale with your business, supporting increased capacity and expanding infrastructure seamlessly.'
  },

  {
    title: 'Cost-Effectiveness',
    text: 'Our solutions optimize IT infrastructure, streamline processes, and leverage efficient technologies, ensuring substantial savings for businesses.'
  },
]

const Features = () => {
  return (
    <div className='gpt3__features section__padding' id='features'>
      <div className="gpt3__features-heading">
        <h1 className='gradient__text'>Why SAP is best for you!!!</h1>
        <p>We allow you to spend more time on growing your business.</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.text + index} />
        ))}
      </div>
    </div>
  )
}

export default Features

// import React from 'react';
// import './features.css';
// import { Feature } from '../../components';

// const featuresData = [
//   {
//     title: 'Data Security',
//     text: 'SAP applications are pivotal in executing business processes and managing vast amounts of sensitive data. Protecting SAP systems from unauthorized access is critical for safeguarding company data integrity and security. Implementing preventive measures such as continuous monitoring, robust firewall configurations, and advanced security software is essential to ensure comprehensive data protection.'
//   },
//   {
//     title: 'Data centralization',
//     text: 'SAP consolidates data from various processes and business functions into a unified, centralized location.'
//   },
//   {
//     title: 'Scalability',
//     text: 'Our solutions scale with your business, supporting increased capacity and expanding infrastructure seamlessly.'
//   },
//   {
//     title: 'Cost-Effectiveness',
//     text: 'Our solutions optimize IT infrastructure, streamline processes, and leverage efficient technologies, ensuring substantial savings for businesses.'
//   },
// ];

// const Features = () => {
//   return (
//     <div className='gpt3__features section__padding' id='features'>
//       <div className="gpt3__features-heading">
//         <div className="gpt3__features-heading-text">
//           <h1 className='gradient__text'>Why SAP is best for you!!!</h1>
//           <p>We allow you to spend more time on growing your business.</p>
//         </div>
//       </div>
//       <div className="gpt3__features-container">
//         {featuresData.map((item, index) => (
//           <Feature title={item.title} text={item.text} key={item.text + index} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Features;

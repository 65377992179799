// import React from 'react';
// import Article from '../../components/article/Article';
// import { blog01, blog02, blog03, blog04, blog05 } from './imports';
// import './blog.css';

// const Blog = () => (
//   <div className="gpt3__blog section__padding" id="blog">
//     <div className="gpt3__blog-heading">
//       <h1 className="gradient__text">A lot is happening, <br /> Hear from our clients.</h1>
//     </div>
//     <div className="gpt3__blog-container">
//       <div className="gpt3__blog-container_groupA">
//         <Article imgUrl={blog01} date="ZHANG WEI, CEO Thermax Limited" text="SAP CRM provides information which leads an insight into action, & maintains focus on productive activity. Thus, SAP CRM Sales helps the sales force of your business to secure customers, and then to develop and maintain beneficial relationship with them. It provides information which leads an insight into action, & maintains focus on productive activity." />
//       </div>
//       <div className="gpt3__blog-container_groupB">
//         <Article imgUrl={blog02} date="THOMAS HERLING, CEO Star International" text="The software effectively manages activities like material receipt, travel, and accounting, essential for logistics and financial oversight." />
//         <Article imgUrl={blog03} date="Karl Max, Founder Varahi Studio" text="SAP CRM on HANA platform ensures quick data retrieval, backed by a supportive developer community and flexible cloud solutions." />
//         <Article imgUrl={blog04} date="Prabhjyot S. CEO Healthrec" text="SAP Hybris impresses with its comprehensive e-commerce features, robust third-party integrations, and seamless SAP Cloud support, making it a standout choice." />
//         <Article imgUrl={blog05} date="WANG LEE, CEO Comos Logistics" text="Once we have the correct access and codes, SAP becomes a powerful tool. I use SAP daily to monitor inventory, refurbish service equipment, and track time and materials efficiently." />
//       </div>
//     </div>
//   </div>
// );

// export default Blog;





import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br/> Hear from our clients.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} people="ZHANG WEI, CEO Thermax Limited" text="SAP CRM enables actionable insights, driving impactful decisions and maximizing productivity. It empowers sales teams to effectively acquire and nurture customer relationships, fostering long-term loyalty. SAP CRM Sales streamlines operations, enhances customer engagement, and supports strategic growth initiatives. With robust tools for data management and sales optimization, SAP CRM stands as a cornerstone of efficiency and customer-centricity. Its seamless integration across platforms ensures a cohesive approach, facilitating operational excellence and sustained business success. SAP CRM is indispensable for businesses seeking to leverage customer data for informed decision-making and maintaining competitive advantage in the market." />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} people="THOMAS HERLING, CEO Star International" text="The software effectively manages activities like material receipt, travel, and accounting, essential for logistics and financial oversight." />
        <Article imgUrl={blog03} people="Karl Max, Founder Varahi Studio" text="SAP CRM on HANA platform ensures quick data retrieval, backed by a supportive developer community and flexible cloud solutions." />
        <Article imgUrl={blog04} people="Prabhjyot S. CEO Healthrec" text="SAP Hybris impresses with its comprehensive e-commerce features, robust third-party integrations, and seamless SAP Cloud support, making it a standout choice." />
        <Article imgUrl={blog05} people="WANG LEE, CEO Comos Logistics" text="Once we have the correct access and codes, SAP becomes a powerful tool. I use SAP daily to monitor inventory, refurbish service equipment, and track time and materials efficiently." />
      </div>
    </div>
  </div>
);

export default Blog;





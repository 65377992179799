import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/bitcoin.png';

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text' style={{ fontSize: 'clamp(2rem, 10vw, 6rem)', lineHeight: '0.8' }}>
          SAP <br />
          <span className="header-subtext">Software</span>
          <span className="header-subtext">Services</span>
          <span className="header-subtext">and</span>
          <span className="header-subtext">Distributors</span>
        </h1>
        <p style={{ fontSize: 'clamp(1rem, 3vw, 2rem)' }}>Effortless Accounting,<br /> Anytime Anywhere.</p>
        <div className='gpt3__header-content__input'>
          <input type="email" placeholder='Your Email Address' style={{ fontSize: '1.5rem', minWidth: '300px', padding: '0.5rem', borderRadius: '5px 0 0 5px', border: 'none', outline: 'none' }} />
          <button type='button' style={{ fontSize: '1.5rem', minWidth: '150px', background: '#ff4820', color: '#fff', border: 'none', borderRadius: '0 5px 5px 0', cursor: 'pointer', transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease' }}>Get Started</button>
        </div>

        <div className='gpt3__header-content__people'>
          <img src={people} alt="people" />
          <p>1,489 people requested access a visit in the last 24 hours</p>
        </div>
      </div>

      <div className="gpt3__header-img">
        <img src={ai} alt="AI" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    </div>
  );
};

export default Header;

import React from 'react'
import './whatgpt3.css'
import { Feature } from '../../components'

const WhatGPT3 = () => {
  return (
   <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
    <Feature
    title={<span style={{ fontSize: '30px', fontWeight: 'bold' }}>What is SAP?</span>}
    text="SAP is a premier provider of integrated IT solutions, specializing in accounting software sales, IT services, data storage, cloud computing, virtualization, shared hosting, digital marketing, and cybersecurity. With a wealth of experience and deep expertise, we deliver cutting-edge solutions tailored to meet the diverse needs of businesses across multiple industries."
  />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="SAP accounting software" text="It is a financial accounting and reporting solution. It records transactions, generates monthly or quarterly reports, and analyzes financial data. This software ensures accurate financial management and supports strategic decision-making." />
      <Feature title="SAP ERP" text="SAP ERP integrates and automates core business processes, offering real-time data and analytics to enhance efficiency, streamline operations, and support informed decision-making across finance, HR, supply chain, and other functions." />
      <Feature title="SAP Business One" text="SAP Business One is SAP's ERP for SMBs, running on SAP HANA or Microsoft SQL Server. It integrates Crystal Reports, supports mobile access, and includes modules for finance, CRM, sales, purchasing, inventory, production, HR, and project management." />
    </div>
  </div>
  )
}

export default WhatGPT3
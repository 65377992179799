// import React from 'react';
// import './article.css';

// const Article = ({ imgUrl, date, text }) => (
//   <div className="gpt3__blog-container_article">
//     <div className="gpt3__blog-container_article-image">
//       <img src={imgUrl} alt="blog_image" />
//     </div>
//     <div className="gpt3__blog-container_article-content">
//       <div>
//         <p>{date}</p>
//         <h3>{text}</h3>
//       </div>
//       {/* <p>Read Full Article</p> */}
//     </div>
//   </div>
// );

// export default Article;





import React from 'react';
import './article.css';

const Article = ({ imgUrl, people, text }) => (
  <div className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      <img src={imgUrl} alt="blog_image" />
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <h3>{text}</h3>
        <p>{people}</p>
      </div>
      {/* Uncomment below if you want to add a "Read Full Article" link */}
      {/* <p><a href="#">Read Full Article</a></p> */}
    </div>
  </div>
);

export default Article;





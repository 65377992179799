import React, {useState} from 'react'
import './navbar.css'
import { RIMenu3Line, RiCloseLine, RiMenu3Line } from 'react-icons/ri'
// import logo from '../../assets/ai.png'

const Menu = () => (
  <>
  <p>
    <a href="#home">Home</a>
          </p>
          <p>
            <a href="#wgpt3">What is SAP?</a>
          </p>
          <p>
            <a href="#possibility">Know More</a>
          </p>
          <p>
            <a href="#features">Features</a>
          </p>
          <p>
            <a href="#blog">Review</a>
          </p>
  </>
)
const Navbar = () => {
  const[toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='gpt3__navbar'>
      <div className="gpt3__navbar-links">
        {/* <div className="gpt3__navbar-links-logo">
          <img src={logo} alt="Logo" />
        </div> */}
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        {/* <p>Sign In</p> */}
        <button type='button'>Profile</button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
        ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='gpt3__navbar-menu_container scale-up-center'>
            <div className="gpt3__navbar-menu_container-links">
              <Menu />
      <div className='gpt3__navbar-menu_container-links-signs'>
        {/* <p>Sign In</p> */}
        <button type='button'>Profile</button>
      </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar